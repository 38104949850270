export default (type, item) => {
  if (item && item.constructor === type) {
      return item;
  }

  if (item === null || item === undefined) {
    return item;
  }

  try {
    if ([Date].includes(type)) {
      return new type(item);
    }

    return type(item);
  } catch (e) {
    return new type(item);
  }
}
