import fetchival from "./fetchival";
import asType from "services/Types/asType";

const createCacheObject = value => ({
  date: new Date(),
  value
});

const applyPostTransforms = schema => result => {
  let newResult = Promise.resolve(result);

  if (schema.transforms && schema.transforms.post) {
    newResult = schema.transforms.post.reduce(
      (acc, transformer) => acc.then(data => transformer(data, schema)),
      newResult
    );
  }

  return newResult;
};

let cachers = {};

const CACHED_FETCH = name => {
  if (!cachers[name]) {
    let cache = {};
    let requestCache = {};
    cachers[name] = cachingTime => schema => {
      const key = JSON.stringify(schema.cachingKeys || schema.endpoint);

      if (
        cache[key] &&
        (cachingTime === 0 ||
          new Date().getTime() - cache[key].date.getTime() < cachingTime)
      ) {
        return Promise.resolve(cache[key].value);
      } else {
        if (requestCache[key]) return requestCache[key];

        const call = FETCH(schema).then(result => {
          cache = {
            ...cache,
            [key]: createCacheObject(result)
          };

          delete requestCache[key];

          return result;
        });

        requestCache[key] = call;
        return call;
      }
    };
  }
  return cachers[name];
};

const STUBBED = schema => {
  return new Promise(res => {
    if (schema.stubVariance) {
      let delay;
      if (Array.isArray(schema.stubVariance)) {
        const [min, max] = schema.stubVariance;
        delay = (max - min) * Math.random() + min;
      } else {
        const threshold = schema.stubVariance;
        delay = threshold * Math.random();
      }

      delay = Math.floor(delay);

      setTimeout(() => {
        res(schema.stubData);
      }, delay);
    } else {
      res(schema.stubData);
    }
  }).then(applyPostTransforms(schema));
};

const FETCH = schema => {
  const methods = fetchival(schema.endpoint, {
    headers: schema.headers,
    responseAs: schema.as
  });

  return methods[schema.method.toLowerCase()](
    Object.keys(schema.payload).length > 0 ? schema.payload : null
  ).then(applyPostTransforms(schema));
};

const XHR = schema => {
  const req = new XMLHttpRequest();
  req.open(schema.method, schema.endpoint, true);

  Object.keys(schema.headers).forEach(key => {
    req.setRequestHeader(key, schema.headers[key]);
  });

  return {
    request: req,
    transformer: applyPostTransforms(schema),
    payload: schema.payload
  };
};

export const Resolvers = {
  CACHED_FETCH,
  FETCH,
  XHR,
  STUBBED,
  AGGREGATED: "AGGREGATED"
};

const defaults = {
  endpoint: "",
  method: "GET",
  payload: {},
  parameters: {},
  headers: {},
  resolver: FETCH,
  as: "json",
  autoAddParameters: false,
  useType: true
};

const castToType = (promise, finalSchema) => {
  if (finalSchema.useType && finalSchema.asType) {
    // @todo: remove me
    // console.log('using Type', finalSchema)
    return promise.then(data => {
      try {
        if (Array.isArray(data) && Array.isArray(finalSchema.asType)) {
          return data.map(item => asType(finalSchema.asType[0], item));
        } else {
          return asType(finalSchema.asType, data);
        }
      } catch (e) {
        return data;
      }
    });
  } else {
    return promise;
  }
};

export const addTransforms = (schema, transforms) => ({
  ...schema,
  transforms: {
    pre: [
      ...(schema.transforms ? schema.transforms.pre : []),
      ...transforms.pre
    ],
    post: [
      ...(schema.transforms ? schema.transforms.post : []),
      ...transforms.post
    ]
  }
});

const _resolveSchema = schema => {
  let newSchema = { ...defaults, ...schema };

  if (schema.transforms && schema.transforms.pre) {
    newSchema = schema.transforms.pre.reduce(
      (acc, transformer) => transformer(acc),
      newSchema
    );
  }

  const paramNames = Object.keys(newSchema.parameters);
  if (newSchema.autoAddParameters && paramNames.length > 0) {
    const queryString = paramNames
      .filter(n => !newSchema.endpoint.includes(`{${n}}`))
      .map(n => `${n}={${n}}`)
      .join("&");
    newSchema.endpoint += `?${queryString}`;
  }
  const newEndpoint = paramNames.reduce(
    (acc, key) => acc.replace(`{${key}}`, newSchema.parameters[key]),
    newSchema.endpoint
  );

  const finalSchema = {
    ...newSchema,
    endpoint: newEndpoint
  };

  const promise = finalSchema.resolver(finalSchema);

  return castToType(promise, finalSchema);
};

export const resolveSchema = schema => {
  if (Array.isArray(schema)) {
    return Promise.all(schema.map(s => _resolveSchema(s)));
  } else if (schema.resolver === Resolvers.AGGREGATED) {
    return Promise.all(schema.schemas.map(s => _resolveSchema(s))).then(res =>
      castToType(applyPostTransforms(schema)(res), schema)
    );
  } else {
    return _resolveSchema(schema);
  }
};

export const groupDispatcher = async (promises, callback) => {
  const results = await Promise.all(promises.map((promise) => promise()));
  callback(results);
};

export default resolveSchema;
