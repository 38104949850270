const responseTypes = ["json", "text", "response"];

const _fetch = (method, url, opts = {}, data = null, queryParams = {}) => {
  const newOpts = {
    ...opts,
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...opts.headers
    },
    responseAs:
      responseTypes[Math.max(0, responseTypes.indexOf(opts.responseAs))]
  };

  if (data !== null) {
    newOpts.body = JSON.stringify(data);
  }

  return fetchival.fetch(url, newOpts).then(response => {
    if (response.status >= 200 && response.status < 300) {
      if (newOpts.responseAs === "response") {
        return response;
      } else if (response.status === 204) {
        return null;
      } else {
        return response[newOpts.responseAs]();
      }
    } else {
      const error = new Error(response.statusText || "error");
      error.response = response;
      error.serviceResponse = response.clone();
      console.error(error);
      throw error;
    }
  });
};

export const fetchival = (url, opts = {}) => {
  const _ = (u, o = {}) => fetchival(`${url}/${u}`, { ...opts, ...o });

  _.get = queryParams => _fetch("GET", url, opts, null, queryParams);
  _.post = data => _fetch("POST", url, opts, data);
  _.put = data => _fetch("PUT", url, opts, data);
  _.patch = data => _fetch("PATCH", url, opts, data);
  _.delete = () => _fetch("DELETE", url, opts);
  _.head = () => _fetch("HEAD", url, { ...opts, responseAs: "text" });

  return _;
};

fetchival.fetch = typeof fetch !== "undefined" ? fetch.bind(window) : null;

export default fetchival;
