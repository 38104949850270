import api, { getJson } from "services/Api/local";

// global styles
import "styles/vars.css";
import "styles/main.css";
import "styles/vendor.css";

Promise.all([
  api(getJson("/config/app.json")),
  api(getJson("/config/sportsbook.json"))
]).then(([appConfig, sportsbookConfig]) => {
  window.__appConfig__ = appConfig;
  window.__sportsbookConfig__ = sportsbookConfig;

  return import("./entry");
});
